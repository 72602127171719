import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout, { fixContentLinks } from "../components/layouts/Layout"
import { ContactSection, Section, LayawaySection } from "../components/elements"
import { AlignCenter, Flex, Col } from "../components/utilities"

import banner from "../images/catbanner.jpg"

const MarginGrid = styled(Flex)`
  margin-bottom: 3rem;
  justify-content: center;
  align-items: center;
`

const InternalPage = ({ data }) => {
  const node = data.allWordpressPage.edges[0].node
  return (
    <Layout>
      <Section>
        {node.acf.is_catalog_page && (
          <MarginGrid>
            <Col>
              <AlignCenter>
                {node.acf.header_left && node.acf.header_left.source_url && (
                  <img src={node.acf.header_left.source_url} />
                )}
              </AlignCenter>
            </Col>
            <Col>
              <AlignCenter>
                Welcome to our Civil War Antiques web catalog.
                <br />
                Please send all Checks and Money orders to :
                <br />
                Dave Taylor P.O. Box 87 Sylvania, OH 43560
                <br />
                419-842-1863
                <br />
                <a href="mailto:civilwardave@frontier.com">
                  Click Here to E-mail Us!
                </a>
                <img src={banner} />
              </AlignCenter>
            </Col>
            <Col>
              <AlignCenter>
                {node.acf.header_right_image &&
                  node.acf.header_right_image.source_url && (
                    <img src={node.acf.header_right_image.source_url} />
                  )}
              </AlignCenter>
            </Col>
          </MarginGrid>
        )}

        {node.title !== "" && (
          <AlignCenter>
            <h2 dangerouslySetInnerHTML={{ __html: node.title }}></h2>
          </AlignCenter>
        )}
        <div
          dangerouslySetInnerHTML={{ __html: fixContentLinks(node.content) }}
        ></div>
      </Section>
      <LayawaySection />
      <ContactSection />
    </Layout>
  )
}

export default InternalPage

export const internalQuery = graphql`
  query InternalPageQuery($id: String!) {
    allWordpressPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          content
          path
          featured_media {
            source_url
          }
          acf {
            is_catalog_page
            header_left {
              source_url
            }
            header_right_image {
              source_url
            }
          }
        }
      }
    }
  }
`
